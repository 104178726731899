<template>
  <v-container
    id="users"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        md="12"
        lg="6"
      >
        <base-material-statistic
          :key="regChart"
          icon="mdi-chart-areaspline-variant"
          color="red"
          title="App Registers"
          :url="regChart"
          keyword="chartsData"
        />
      </v-col>

      <v-col
        cols="12"
        md="12"
        lg="6"
      >
        <base-material-statistic
          :key="oauthChart"
          icon="mdi-chart-areaspline-variant"
          color="yellow"
          title="Apps Usage"
          :url="oauthChart"
          keyword="chartsData"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <base-material-log-table
          color="indigo"
          icon="mdi-update"
          title="Application Logs"
          :url="logTable"
          :date-picker="true"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      regChart: '/v1/api/stats/apps/reg', // /:start/:end"
      oauthChart: '/v1/api/stats/apps/auth', // /:start/:end"
      logTable: '/v1/api/stats/apps/logs', // /:start/:end"
    }),
  }
</script>
